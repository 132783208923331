@import '../../sass/styles.scss';

.footer {
    width: 100%;
    background-color: $primary_700;
    padding: 49px 0;
    position: relative;
    overflow: hidden;
}

.blurried_coins {
    @include center_align();
    position: absolute;
    height: 100%;
    top: -10%;

    @media #{$mobile} {
        height: 60%;
        @include center_middle_align();
        position: absolute;
    }
}

.logo {
    width: 220px;
    margin-bottom: 28px;
}

.social_media_icons {
    @include flex($justify_content: flex-start);
    gap: 0 18px;

    @media #{$mobile} {
        margin: 0 auto 37px auto;
        width: fit-content;
    }
}

.icon {
    @include center_middle_align();
    position: absolute;
}

.section {
    padding: 0;
    margin: 0;
    text-align: left;

    @media #{$mobile} {
        width: 100%;
        text-align: center;
    }
}

.list_item {
    @include title($white_200, $main_font, 15px, 400, uppercase, 0px);
    margin-top: 13px;
    list-style: none;
    position: relative;
    z-index: 10000;

    &:hover,
    &:focus,
    &:active {
        color: $primary_400;
    }
}

.list_link {
    text-decoration: none;
}

.navigation_cnt {
    @include flex(row, wrap, space-between);
    align-items: flex-start;

    @media #{$mobile} {
        flex-direction: column;
    }
}

.logoaff {
    &:nth-child(1) {
        height: 56px;
    }

    &:nth-child(2) {
        margin-left: 27px;
        height: 73px;

        @media #{$mobile} {
            margin: auto;
        }
    }

    @media #{$mobile} {
        display: block;
        margin: 19px auto;
    }
}

.providers {
    overflow: hidden;
    padding: 20px;
    white-space: nowrap;
    background-color: $primary_900;
    position: relative;

    &:hover {
        & .providers_cnt {
            animation-play-state: paused;
        }
    }
}

.providers_cnt {
    display: inline-block;
    animation: 35s slide infinite linear;
}

.operator_img {
    height: 30px;
    margin: 0 90px;

    @media #{$mobile} {
        margin: 0 75px;
    }
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.copyright_cnt {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    background-color: $white_200;
    position: relative;
}

.copyright {
    @include title($primary_600, $main_font, 16px, 600, unset, 0px);

    @media #{$mobile} {
        font-size: 15px;
    }
}

.separator {
    display: none;

    @media #{$mobile} {
        display: block;
        width: 80%;
        margin: auto;
        background-color: transparent;
        opacity: 39%;
        border: 1px dashed $white;
        margin-top: 19px;
    }
}