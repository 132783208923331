@import './sass/styles.scss';

body {
    @include gradient-bg(radial, circle, $primary_400 0%, $primary_700 100%);

    &.mobileMenuOpen {
        overflow: hidden;
    }
}

.container {
    width: 95%;
    margin: auto;

    @media #{$mobile} {
        width: 90%;
    }
}

.sliderHp_container {
    width: 95%;
    margin: 0 auto 120px auto;

    @media #{$mobile} {
        width: 100%;
        margin: 0 auto;
    }
}

.wh-wrapper {
    background-color: $white_200;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 95%;
    margin: auto;
    box-shadow: 0 4px 4px 0 rgba(#000000, .25);

    @media #{$mobile} {
        width: 100%;
    }
}

.button {
    &--btn-primary-lg {
        @include button(264px,
            60px,
            uppercase,
            $white,
            $linear-light_green-gradient-rightAngle,
            25px,
            bold,
            $main_font,
            100px,
            linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
            none, 1px);
    }

    &--btn-primary-md {
        @include button(211px,
            46px,
            uppercase,
            $white,
            $linear-light_green-gradient-rightAngle,
            20px,
            bold,
            $main_font,
            100px,
            linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
            none, 1px);
    }

}

.button-label {
    &--label-outline {
        @include buttonText($linear-light_green-gradient, transparent)
    }

    &--label-accent-outline {
        @include buttonText($linear-purple-gradient-rightAngle, transparent)
    }
}

.account-cards-title {
    @include title($white_200, $main_font, 25px, 400, none, 0);
    line-height: 25px;

    @media #{$mobile} {
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
    }

    &.bigTitle {
        font-weight: 600;
        display: block;
    }

    &.walletColor {
        color: $purple;
    }

    &.depositColor {
        color: #085859;
    }

    &.winningColor {
        color: $secondary_400;
    }

    &.pendingColor {
        color: $brick;
    }
}

.social-media-cnt {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    @include gradient-bg(linear, 180deg, $white_200 0%, #AACAA9 100%);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        padding: 1px;
        background: linear-gradient(90deg, #8C421D 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%);
        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask-composite: exclude;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $white_200 inset !important;
    border-radius: 10px;
}

.overlay {
    position: fixed;
    z-index: 100000 !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.71);
    overflow-x: hidden;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

//pagination

.paginationContainer {
    margin-top: 29px;
    margin-bottom: 18px;
    margin-right: 24px;
    display: flex;
    justify-content: flex-end;
}

.rowsLabel {
    @include title($primary_700, $main_font, 16px, 400, unset, unset);
}

.rowsDrop {
    background-color: transparent;
    border: none;
    @include title($primary_700, $secondary_font, 16px, bold, unset, unset);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0 0 0 10px;
    padding-right: 20px;

    &:focus {
        outline: none;
    }
}

.arrowIcon {
    height: 15px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 9px solid $primary_700;
        top: 50%;
    }
}

.previewArrow {
    &::before {
        transform: rotate(-90deg) translateY(-100%) translateX(5px);
    }
}

.nextArrow {
    &::before {
        transform: rotate(90deg) translateY(-100%) translateX(-5px);
    }
}

.thumbFilter {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.paginationString {
    margin: 0 16px;
    @include title($primary_700, $secondary_font, 16px, 400, unset, unset);
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 9px solid $primary_700;
        top: 50%;
        transform: translateY(-35%) rotate(180deg);
        left: -35px;
    }
}