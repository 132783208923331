@import '../../../sass/styles.scss';

.media_bg {
    background-color: green;
    @include gradient-bg(linear, 90deg, rgba($primary_400, 90%) 9%, rgba($primary_700, 0%) 100%);
    width: 95%;
    margin: auto;
    height: 300px;
    border-radius: 12px;
    z-index: 0;
    position: relative;

    &::before {
        content: '';
        @include gradient-bg(linear, 90deg, rgba($primary_400, 90%) 9%, rgba($primary_700, 0%) 100%);
        position: absolute;
        inset: 0;
        border-radius: inherit;
        z-index: 1;
    }

    @media #{$mobile} {
        height: 181px;
    }
}

.promo_text_cnt {
    position: absolute;
    @include middle_align();
    z-index: 1;
    margin-left: 13px;
}

.promo_text {
    display: table-caption;
    position: relative;
    @include title($white_200, $secondary_font, 40px, bold, uppercase, unset);
    line-height: 40px;
    @media #{$mobile} {
        font-size: 30px;
        line-height: 30px;
    }

}

.media_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include center_middle_align();
    position: absolute;
    z-index: 0;
    border-radius: 12px;
}


.articles_cnt {
    @include wh-wrapper(95%);
    margin-top: 22px;
    @media #{$mobile} {
        width: 100%;
        margin-top: 17px;
    }
}

.article_wrapper {
    width: 90%;
    margin: auto;
}

.thumbnail {
    height: auto;
    width: 100%;
    border-radius: 15px;

}

.article_title {
    @include title($primary_900, $secondary_font, 30px, bold, capitalize, unset);
    margin-bottom: 10px;
}


.article_content {
    h1,
    h2,
    h3 {
        @include title($primary_600, $main_font, 20px, 500, unset, unset);
    }

    h1 {
        font-size: 22px
    }

    span {
        @include title($primary_600, $main_font, 16px, 400, unset, unset);
        margin: 0 5px;
    }

    li,p {
        @include title($primary_600, $main_font, 16px, 400, unset, unset);
    }
}

.wrapper_articles {
    width: 95%;
    margin: auto;
    margin-top: 70px;
    @media #{$mobile} {
        width: 90%;
        margin-top: 40px;
    }
}