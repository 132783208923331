@import "../../sass/styles.scss";


.loader_container {
    margin: auto;
    border: 3px solid $white_200;
    border-top: 3px solid $primary_200;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.big {
    border: 6px solid $primary_200;
    border-top: 6px solid $primary_600;
    width: 30px;
    height: 30px;
}

.fallBack_cnt {
    @include center_middle_align();
    position: absolute;
    @include flex(row,wrap,center);
    gap: 12px;
}

.white_dot {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $white;
}

.green_cnt {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $primary_400;
    position: relative;
    animation: blink 1.5s infinite ease-in-out;
}

.mIcon {
    height: 20px;
    @include center_middle_align();
    position: absolute;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}