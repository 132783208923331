// Font family
@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&family=Agdasima:wght@400;700&display=swap');

$main_font: "Afacad", sans-serif;
$secondary_font: "Agdasima", sans-serif;

// Colors
$primary_200: #B2D3B2;
$primary_400: #5EB45B;
$primary_600: #254624;
$primary_700: #1B351D;
$primary_900: #0D190D;
$secondary_300: #F4BB43;
$secondary_400: #FFA71E;
$secondary_600: #CB7702;
$secondary_900: #191002;
$error: #E95111;
$brick: #642601;
$accent_300: #E4B4F3;
$accent_400: #9E41B9;
$accent_600: #471D53;
$accent_700: #260530;
$accent_900: #18021F;
$black: #121212;
$white: #FFFFFF;
$purple: #422277;
$white_200: #F1FCF1;
$white_400: #E9E9E9;
$white_600: #9EA6A5;
$white_700: #575C57;
$white_900: #363836;

$radial-green-gradient: radial-gradient(circle, $primary_400 0%, $primary_700 100%);
$linear-green-gradient: linear-gradient(90deg, #5AAC57 0%, $primary_900 100%);
$linear-orange-gradient: linear-gradient(0deg, $secondary_300 47%, #885521 100%);
$radial-orange-gradient: radial-gradient(circle, $secondary_400 0%, $secondary_900 120%);
$radial-green_secondary-gradient: radial-gradient(circle, $primary_400 0%, $white_200 100%);
$linear-purple-gradient: linear-gradient(0deg, $accent_400 0%, $accent_600 100%);
$linear-purple-gradient-rightAngle: linear-gradient(90deg, $accent_400 0%, $accent_600 100%);
$linear-light_green-gradient-rightAngle: linear-gradient(180deg, $primary_400 0%, $primary_600 100%);
$linear-light_green-gradient: linear-gradient(90deg, $primary_400 0%, $primary_600 100%);

// Media queries
$minimobile: "(max-width: 345px)";
$smallmobile: "(max-width: 359px)";
$mediummobile: "(max-width: 390px)";
$mobile: "(max-width: 768px)";
$mobileP: "(max-width: 479px)";
$mobileL: "(min-width: 480px) and (max-width: 767px)";
$nonMob: "(min-width: 768px)";
$nonPortable: "(min-width: 992px)";
$tablet: "(min-width: 768px) and (max-width: 991px)";
$smallDesktop: "(min-width: 992px) and (max-width: 1199px)";
$desktop: "(min-width: 1200px)";
$largeDesktop: "(min-width: 1366px)";
$mobTab: "(max-width: 991px)";
$tabDesk: "(min-width: 768px) and (max-width: 1199px)";
$nonDesk: "(max-width: 1199px)";
$ipad: "(device-width: 768px) and (device-height: 1024px)";