@import '../../sass/styles.scss';

.header {
    background-color: transparent;
    width: 100%;
    padding: 20px 0;
    position: relative;
}

.header_content {
    @include flex(row, nowrap, space-between);
}

.logo_link {
    z-index: 100000;
}

.logo {
    height: 50px;
    z-index: 100000;

    @media #{$tabDesk} {
        height: 35px;
    }

    @media #{$mobile} {
        height: 22px;
    }
}

.logo_big {
    height: 38px;
}

.logged_in_mngt {
    display: none;

    @media #{$mobile} {
        @include flex();
        gap: 22px;
        position: relative;
        margin: 106px 0 41px 0;
        width: 100%;
        height: 70px;
        background-color: $primary_700;
    }
}

.logged_in_user {
    @include flex();
    cursor: pointer;
}

.logged_in_logout_cnt {
    display: none;

    @media #{$mobile} {
        display: block;
        width: 100%;
        cursor: pointer;
        position: absolute;
    }
}

.logged_in_logout_inside {
    @include flex($justify-content: left);
    background-color: $white_200;
    height: 55px;
    gap: 12px;
    padding-left: 22px;
}

.logged_out_sign_upLbl {
    @include title($primary_600, $main_font, 15px, 400, unset, unset);
}

.logged_out_sign_upBtn {
    background-color: transparent;
    border: none;
    @include title($primary_400, $main_font, 15px, 700, uppercase, unset);
    padding: 0;
    cursor: pointer;

    &:focus,
    &:active {
        text-decoration: underline;
    }
}

.navbar {
    @include flex($justify-content: right);
    width: 100%;
}

.header_nav {
    @include flex(row, nowrap, right);

    @media #{$mobile} {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        transition: 0.6s;
        height: 100vh;
        z-index: 2;
        @include gradient-bg(radial, circle, $primary_400 0%, $primary_700 100%);
        display: block;
        justify-items: baseline;
        align-content: baseline;
        z-index: 10000;
    }
}

.open {
    @media #{$mobile} {
        left: 0;
    }
}

.close {
    @media #{$mobile} {
        left: -100%;
    }
}

.list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list_link {
    margin-right: 37px;

    & .list_item {

        &:hover,
        &:focus,
        &:active {
            color: $primary_400;

            @media #{$mobile} {
                color: $white;
            }
        }
    }

    @media #{$tabDesk} {
        margin-right: 15px;
    }

    @media #{$mobile} {
        display: block;
        text-decoration: none;
        padding: 10px 0;
        margin: 0;
        width: 100%;

        &:nth-child(1) {
            margin-top: 106px;
        }

        &:hover,
        &.focus,
        &.active {
            background-color: $primary_700;
        }
    }

}

.list_item {
    display: inline-block;
    @include title($white_200, $main_font, 18px, 400, capitalize, 0px);

    @media #{$tabDesk} {
        font-size: 16px;
    }

    @media #{$tablet} {
        font-size: 14px;
    }

    @media #{$mobile} {
        display: block;
        margin: 0 0 0 19px;
    }
}

.signup {
    background-color: transparent;
    border: none;
    @include title($primary_200, $main_font, 18px, 600, uppercase, 0px);
    padding: 0;
    cursor: pointer;
    text-decoration: underline;

    @media #{$tabDesk} {
        margin: 0 10px;
        font-size: 16px;
    }

    @media #{$mobile} {
        display: none;
    }
}

.loginBtn {
    @include button(150px,
        38px,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        18px,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
    padding: 0;

    @media #{$tabDesk} {
        width: 130px;
        height: 35px;
        font-size: 16px;
    }

    @media #{$mobile} {
        margin-right: 18px;
    }
}

.action_cnt {
    @include flex();
    gap: 10px;

    @media #{$tabDesk} {
        margin-left: 10px;
    }
}

.loginBtn {
    @media #{$tabDesk} {
        margin-left: 0px;
    }
}

.burgermenu_container {
    position: relative;
    display: none;
    flex-direction: column;
    cursor: pointer;
    width: 30px;
    height: 17px;
    z-index: 100000;

    @media #{$mobile} {
        display: flex;
    }

    i {
        background-color: $primary_400;
        width: 18px;
        height: 2.5px;
        transition: all ease 0.5s;
        clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);

        &:nth-child(2) {
            width: 10px;
            margin: 2.5px 4px;
        }
    }
}

.openbtn:nth-child(1) {
    transform: rotate(45deg) translateY(7px) translateX(1px);
    clip-path: unset;
    background-color: $primary_200;
}

.openbtn:nth-child(2) {
    opacity: 0;
    clip-path: unset;
    background-color: $primary_200;
}

.openbtn:nth-child(3) {
    transform: rotate(-45deg) translateY(-6px);
    clip-path: unset;
    background-color: $primary_200;
}

.closebtn:nth-child(1) {
    transform: rotate(0) translateY(0);
}

.closebtn:nth-child(2) {
    opacity: 1;
}

.closebtn:nth-child(3) {
    transform: rotate(0) translateY(0);
}

.wallet_cnt {
    display: inline-block;
    cursor: pointer;
    width: auto;
    height: 38px;
    padding: 0 10px;
    border-radius: 100px;
    @include gradient-bg(radial, circle, $secondary_400 0%, $secondary_900 100%);
    border: 1px solid $secondary_400;
    text-align: center;
    @include flex();
    gap: 9px;

    @media #{$tabDesk} {
        width: 100px;
        height: 35px;
    }

    @media #{$tablet} {
        width: 90px;
        height: 35px;
    }

    @media #{$mobile} {
        margin-right: 18px;
        width: 92px;
        height: 30px;
    }
}

.wallet_amount {
    @include title($white, $secondary_font, 20px, bold, unset, 0px);

    @media #{$tabDesk} {
        font-size: 16px;
    }

    @media #{$mobile} {
        font-size: 15px;
    }
}

.wallet_icon {
    @media #{$tabDesk} {
        height: 15px;
    }

    @media #{$mobile} {
        height: 10px;
    }
}

.currency {
    font-size: 14px;
    margin-left: 2px;

    @media #{$tabDesk} {
        font-size: 12px;
    }

    @media #{$mobile} {
        font-size: 10px;
    }
}

.menu_wallet {
    width: 120px;
    height: 38px;

    & .wallet_icon {
        height: 18px;
    }

    & .wallet_amount {
        font-size: 20px;
    }

    & .currency {
        font-size: 15px;
    }
}

.user_big_cnt {
    position: relative;

    @media #{$mobile} {
        display: none;
    }
}

.user {
    @include flex();
    gap: 8px;
    cursor: pointer;
}


.number {
    @include title($primary_200, $secondary_font, 16px, bold, unset, 0.5px);

    @media #{$tablet} {
        font-size: 14px;
    }
}

.logged_in_cntry_code {
    @include title($primary_400, $secondary_font, 16px, bold, unset, 0.5px);

    @media #{$tablet} {
        font-size: 14px;
    }
}

.loggeed_in_nmbr {
    color: $primary_200;
    margin-left: 5px;
}


.arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 8px solid $primary_200;
    transition: all ease 0.5s;
}

.userAct_open {
    transition: all ease 0.5s;
    transform: rotate(180deg);
}

.user_mngt {
    @include borderBoxContainer(160px,
        102px,
        $white_200,
        none,
        20px,
        -1px,
        1px dashed $primary_400,
        0px,
        transparent);
    @include center_align();
    position: absolute;
    top: -500px;
    transition: all ease 0.5s;
}

.user_mngt_open {
    top: 30px;
    transition: all ease 0.5s;
    z-index: 1000000;
}

.user_mngt_content {
    width: 90%;
    @include center_middle_align();
    position: absolute
}

.mngt_part {
    @include flex($justify-content: left);
    gap: 14px;
    cursor: pointer;
    text-decoration: none;
}

.mngt_separator {
    width: 130px;
    background: none;
    border: 1px dashed $primary_400;
    margin: 6px auto;
}

.mngt_label {
    @include title($primary_900, $main_font, 18px, 500, capitalize, 0px);
}

.logout_label {
    color: #C52B0C;
}