@import '../../sass/styles.scss';

.input_field_container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 8px;
    margin: 9px 0 0;
    width: 100%;
}

.input_label {
    @include title($primary_600, $main_font, 16px, 500, capitalize, 0px);
    margin-left: 18px;
}

.input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid $primary_600;
    border-radius: 10px;
    outline: none;
    background-color: transparent;
    color: $primary_600;
    @include title($primary_600, $main_font, 16px, 500, unset, 0px);
}

.input_field {
    width: 100%;
    height: 100%;
    padding: 12px 18px;
    border: none;
    background-color: transparent;
    outline: none;
}

.input_field::placeholder {
    color: rgba($primary_600, 0.5);
}

.eye_icon,
.calendar_icon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.country_flag {
    height: 17px;
}

.country_cnt {
    @include flex();
    gap: 10px;
    margin-left: 22px;
}

.country_code {
    @include title($primary_400, $secondary_font, 16px, bold, unset, 0px);
}

.input_field::-webkit-calendar-picker-indicator {
    background: url('../../assets/Icons/calendar.svg') center/80% no-repeat;
    color: black;
}

.textArea {
    min-height: 150px;
    resize: vertical;

    @media #{$mobile} {
        border-radius: 20px;
    }
}


.amountField {
    .input_wrapper {
        background-color: $primary_600;
        color: $white_200;
        border: 1px solid $primary_400;
    }
    .input_field {
        color: $white_200;
    }
}

.web_currency {
    @include title($white_200, $secondary_font, 20px, bold, unset,unset);
    margin-right: 9px;
}