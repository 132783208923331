@import '../../sass/styles.scss';

.title {
    @include title($primary_600, $main_font, 24px, bold, uppercase, 0);
    display: block;

    @media #{$mobTab} {
        font-size: 20px;
    }
}

.auth_cnt {
    @include white_container(fit-content, auto, $white_200, 30px, $primary_400);
    @include center_align();
    top: 90px;

    @media #{$mobTab} {
        width: 340px;
        height: 568px;
    }
}

.estimatePopup {
    @media #{$mobTab} {
        width: fit-content;
        height: 568px;
    }
}

.content {
    padding: 27px 40px;
    position: relative;

    @media #{$mobTab} {
        padding: 18px 23px;
        overflow: scroll;
        height: 500px;
        -webkit-overflow-scrolling: touch;
        top: 50%;
        transform: translateY(-50%);

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.header {
    @include flex(row, nowrap, space-between);
}

.auth_body {
    @include flex($align-items: stretch);
    margin-top: 24px;
    gap: 24px;

    @media #{$mobTab} {
        flex-direction: column;
        align-items: center;
        margin-top: 44px;
    }
}

.close_btn {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 30px;
    height: 17px;
    z-index: 100;

    i {
        background-color: $primary_900;
        width: 18px;
        height: 2.5px;
        transition: all ease 0.5s;
    }
}

.close_x:nth-child(1) {
    transform: rotate(45deg) translateY(3px) translateX(4px);
}

.close_x:nth-child(2) {
    opacity: 0;
}

.close_x:nth-child(3) {
    transform: rotate(-45deg) translateY(0px);
}

.left_section {
    @media #{$mobTab} {
        display: block;
        width: 223px;
        height: 202px;
    }
}

.signInSignUp {
    & .left_section {
        position: relative;
        @include borderBoxContainer(350px, 100%, transparent, none, 10px, -1px, 1px dashed $primary_400, 1px, transparent);
        padding: 20px;
        display: block;

        @media #{$mobTab} {
            padding: 0;
            display: none;
            width: 100%;

            &:before {
                border: none;
            }
        }
    }

    & .right_section {
        position: relative;
        width: 400px;
        @include borderBoxContainer(350px, fit-content, transparent, none, 10px, -1px, 1px dashed $primary_400, 1px, transparent);
        padding: 20px;
        display: block;

        @media #{$mobTab} {
            padding: 0;
            display: none;
            min-width: auto;
            max-width: auto;
            width: 100%;

            &:before {
                border: none;
            }
        }
    }

    & .activate_section {
        position: relative;

        @media #{$mobTab} {
            display: block;
            width: 100%;
        }
    }

    & .close_btn {
        margin-left: auto;
    }

}

.sign_in-cnt,
.reset_cnt,
.payment_profile {
    & .left_section {
        width: 300px;
        background: url('../../assets/GeneralImages/flares_draws.png') no-repeat center center,
            radial-gradient(circle, $primary_400 0%, $primary_700 100%);
        background-size: 200%;
        width: 277px;
        height: auto;
        border-radius: 30px;
        position: relative;
    
    }
}


.edit_profile {
    .action_cnt_sm {
        margin: 15px 0 0 auto;
        display: flex;
    }
}

.gameErrorSuccess {
    .auth_body {
        margin-top: 0;
        padding: 50px;
    }

    & .content {
        @media #{$mobTab} {
            padding: 35px 23px;
            overflow: scroll;
            height: auto !important;
            -webkit-overflow-scrolling: touch;
            top: none;
            transform: none;
        }
    }

    &.auth_cnt {
        @media #{$mobTab} {
            height: auto;
        }
    }
}

.edit_profile {
    .auth_body {
        margin-top: 0;
    }

    & .content {
        @media #{$mobTab} {
            padding: 35px 23px;
            overflow: scroll;
            height: auto !important;
            -webkit-overflow-scrolling: touch;
            top: none;
            transform: none;
        }
    }

    &.auth_cnt {
        @media #{$mobTab} {
            height: auto;
        }
    }
}

.reset_cnt {
    .auth_body {
        margin-top: 0;
    }

    & .content {
        @media #{$mobTab} {
            padding: 35px 23px;
            overflow: scroll;
            height: auto !important;
            -webkit-overflow-scrolling: touch;
            top: none;
            transform: none;
        }
    }

    &.auth_cnt {
        @media #{$mobTab} {
            height: auto;
        }
    }
}

.payment_profile {
    .auth_body {
        margin-top: 0;
        padding: 20px;

        @media #{$mobTab} {
            padding: 10px;
        }
    }

    & .content {
        @media #{$mobTab} {
            padding: 35px 23px;
            overflow: scroll;
            height: auto !important;
            -webkit-overflow-scrolling: touch;
            top: none;
            transform: none;
        }
    }

    &.auth_cnt {
        @media #{$mobTab} {
            height: auto;
        }
    }
}

.sign_up_image {
    position: absolute;
    bottom: 0;
    left: -40px;
    z-index: 100;

    @media #{$mobTab} {
        height: 100%;
        @include center_align();
    }
}

.coins_splash {
    position: absolute;
    top: 90px;
    left: -15px;

    @media #{$mobTab} {
        @include center_middle_align();
        position: absolute;
        top: 43%;
        height: 100%;
    }
}

.white_balls {
    position: absolute;
    @include center_middle_align();
}

.black_man {
    position: absolute;
    bottom: 0;

    @media #{$mobTab} {
        height: 100%;
        @include center_align();
    }
}

.flares {
    position: absolute;
    left: 0;
}

.action_cnt {
    width: fit-content;
    margin: 24px auto;
    text-align: center;
}

.action_cnt_sm {
    width: fit-content;
    margin: 15px auto 0 auto;
    text-align: center;

    @media #{$mobTab} {
        margin: 15px auto;
    }
}

.action_cnt2 {
    margin-top: 7px;
    text-align: center;
    position: relative;
}

.action_label {
    @include title($primary_600, $main_font, 15px, 400, unset, unset);
}

.action_btn {
    @include title($primary_400, $main_font, 15px, bold, uppercase, unset);
    background: none;
    border: none;
    padding: 0;
    margin-left: 5px;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}


.sign_in_btn {
    @include button(144px,
        46px,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        20px,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
}

.reset_btn {
    background: none;
    border: none;
    @include title($primary_600, $main_font, 18px, 600, uppercase, 0px);
    margin-right: 20px;
}

.save_btn {
    @include button(105px,
        46px,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        20px,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
}

.verify_btn {
    @include button(105px,
        46px,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        20px,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
    margin: 15px 0;
}

.infocnt {
    @include flex($align-items: flex-start);
    gap: 7.5px;
    margin-bottom: 10px;
}

.infos_img {
    display: inline-block;
}

.info_p {
    padding: 0;
    margin: 0;
    text-align: left;
    width: 243px;
    @include title($secondary_400, $main_font, 16px, 500, unset, 0px);
}


.form {
    text-align: center;
    padding: 0 100px;

    @media #{$mobTab} {
        width: 100%;
        padding: 0;
    }
}

.otp_title_bg {
    @include title($primary_600, $main_font, 18px, bold, unset, 0px);
    display: block;
    text-align: center;
    margin: 18px 0 0;
}

.otp_title_sm {
    @include title($primary_600, $main_font, 15px, 500, unset, 0px);
    display: block;
    text-align: center;
    margin-top: 14px;
    margin-bottom: 12px;
}

.inputsContainer {
    margin-top: 12px;
    @include flex(row, nowrap, center);
    gap: 8px;
}

.input_item {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid $primary_600;
    @include title($primary_600, $main_font, 30px, bold, unset, 0px);
    text-align: center;
    outline: none;
}

.resend_btn {
    background: transparent;
    border: none;
    cursor: pointer;
    @include title($primary_400, $main_font, 15px, bold, unset, 0px);
    text-transform: uppercase;
    margin: 19px auto;
    width: fit-content;
    display: block;
}

.next_btn {
    @include button(106px,
        46px,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        20px,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
    margin: auto;
}

.complete_cnt {
    width: fit-content;
    margin: 15px auto 0;
}

.complete_btn {
    @include button(auto,
        46px,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        20px,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
    padding: 0 10px;
}

.input_label {
    @include title($primary_600, $main_font, 16px, 500, capitalize, 0px);
    display: block;
    margin-left: 18px;
    margin-top: 9px;
}

.input_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid $primary_600;
    border-radius: 10px;
    outline: none;
    background-color: $white_200;
    color: $primary_600;
    @include title($primary_600, $main_font, 16px, 500, unset, 0px);
}

.select_input {
    width: 100%;
    height: 100%;
    padding: 12px 18px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
}

.right_section {
    width: 300px;
}

.payment_success {
    overflow: hidden;
    display: block;
    background-color: $primary_700;

    &::before {
        content: none;
    }

    .auth_body {
        display: block;
        margin-top: 0;
    }

    .successDeposit_coins {
        position: absolute;
        top: 0;
        height: 100px;
        left: 0;
    }

    .successIcon {
        display: block;
        margin-bottom: 16px;
    }

    .sucessDeposit_title {
        display: block;
        @include title($primary_200, $main_font, 24px, bold, uppercase, 0px)
    }

    .sucessDeposit_message {
        display: block;
        @include title($white_200, $main_font, 15px, bold, unset, 0px);
    }
}

.payment_response {
    height: auto;

    @media #{$mobTab} {
        width: 320px;
    }

    .auth_body {
        display: block;
        margin-top: 0;
        width: 348px;

        @media #{$mobTab} {
            width: auto;

        }
    }

    .status_paystack {
        border: 1px solid $secondary_400;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        text-align: center;
        line-height: 14px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 12px;
    }

    .status_inside {
        display: block;
        @include center_middle_align();
        position: absolute;
        display: flex;
    }

    .point {
        margin: 1px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background-color: $secondary_400;
    }

    .status_paystack_lbl {
        @include title($secondary_400, $secondary_font, 20px, bold, capitalize, 0px);
        display: inline-block;
        vertical-align: middle;
        margin-left: 7px;
        margin-bottom: 12px;
    }

    .info_paystack {
        @include borderBoxContainer(100%, fit-content, $white, none, 10px, -1px, 1px dashed $primary_400, 1px, transparent);
    }

    .row_paystack {
        @include flex(row, nowrap, space-between);
        padding: 10px;
    }

    .row_paystack_label {
        @include title($primary_600, $main_font, 13px, 400, capitalize, 0px);
    }

    .row_paystack_value {
        @include title($primary_600, $main_font, 18px, 500, capitalize, 0px);

        @media #{$mobTab} {
            font-size: 14px;
        }
    }

    .separator {
        width: 50%;
        border: 1px dashed $primary_400;
        margin: auto;
    }

    .amount_paystack_cnt {
        @include borderBoxContainer(100%, 54px, linear-gradient(90deg, #5AAC57 0%, rgba($primary_600, 100%)), none, 10px, -1px, 1px dotted $primary_400, 1px, transparent);
        position: relative;
        margin-top: 16px;
    }

    .amount_lbl {
        position: absolute;
        top: 8px;
        left: 13px;
        @include title($white, $main_font, 15px, 400, uppercase, 0px);
    }

    .amount_value {
        @include title($secondary_400, $secondary_font, 32px, bold, unset, 0px);
        position: absolute;
        right: 13px;
        bottom: 8px;
    }

    .currency_paystack {
        font-size: 16px;
        margin-left: 5px;
    }

    .content {
        @media #{$mobTab} {
            padding: 35px 23px;
            overflow: scroll;
            height: auto !important;
            -webkit-overflow-scrolling: touch;
            top: none;
            transform: none;
        }
    }
}

.inputGroup {
    position: relative;
    margin: 10px 0;
}

.terms {
    @include title($primary_700, $main_font, 14px, 400, unset, unset);
    vertical-align: middle;
}

.inputCheckBox {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}


// sign in sign up 

.toggle_btns {
    display: none;

    @media #{$mobTab} {
        display: block;
    }
}

.toggle_item {
    background-color: transparent;
    width: 60px;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid $primary_400;
    outline: none;
    @include title($primary_400, $main_font, 14px, 600, uppercase, unset);
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;

    &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.toggle_item_active {
    background-color: $primary_400;
    color: $white;
}

// estimate table design 

.simulation_cnt {
    width: fit-content;
    margin: 26px auto 23px;
    text-align: center
}

.subTitle {
    @include title($primary_600, $main_font, 16px, 500, unset, unset);
    display: block;
    margin-bottom: 14px;
}

.simulation_actions {
    @include flex();
    gap: 19px;
}

.action_btn_stm {
    width: 48px;
    height: 48px;
    @include gradient-bg(radial, circle, $secondary_400 0%, $secondary_900 100%);
    border: 1px solid $secondary_400;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
}

.act_icon {
    @include center_middle_align();
    position: absolute;
}

.board_cnt {
    width: 154px;
    height: 48px;
    border: 1px solid $primary_400;
    background-color: $primary_600;
    text-align: center;
    line-height: 48px;
    border-radius: 100px;
    position: relative;
}

.bet {
    @include title($white, $secondary_font, 30px, bold, unset, unset);
}

.currency {
    @include title($primary_200, $secondary_font, 20px, bold, unset, unset);
    @include middle_align();
    position: absolute;
    right: 22px;

}