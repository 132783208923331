@import '../../sass/styles.scss';

.counterTableContainer {
    padding: 20px;
    @include flex(column);
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;


    th {
        background-color: $primary_900;
        padding: 15px;
        text-align: left;
        padding-left: 60px;
        @include title($white_200, $main_font, 16px, bold, uppercase, unset);

        @media #{$mobile} {
            font-size: 12px;
            padding: 10px;
            text-align: center;
        }

    }

    td {
        padding: 10px;
        text-align: left;
        padding-left: 60px;
        @include title($primary_600, $secondary_font, 20px, bold, capitalize, unset);

        @media #{$mobile} {
            font-size: 15px;
            padding-left: 23px;
            text-align: center;
        }
    }

    .evenRow {
        background-color: $white_200;
    }

    .oddRow {
        background-color: #DEF0DE;
        border-bottom: 1px dashed rgba($primary_600, 0.3);
    }
}

.multiplierGameFont {
    color: $primary_600 !important;
    font-family: $main_font !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: unset !important;
}

@media #{$mobTab} {
    .tableContainer {
        width: 1000px;
    }
}