@import '../../sass/styles.scss';

.media_element {
    @include borderBoxContainer(auto, auto, rgba(#F1FFF0, 0.3), none, 12px, -1px, 1px dashed $primary_400, 1px, transparent);
    display: grid;
    grid-template-rows: min-content;
    gap: 1rem;
    padding: 0.5rem 0.5rem 1.2rem 0.5rem;

    @media #{$mobile} {
        padding: 11px 11px 0;
        gap: 0;
    }
}

.media_bg {
    background-color: green;
    @include gradient-bg(linear, 90deg, rgba($primary_400, 90%) 9%, rgba($primary_700, 0%) 100%);
    width: auto;
    height: auto;
    aspect-ratio: 312/154;
    border-radius: 12px;
    z-index: 0;
    position: relative;

    &::before {
        content: '';
        @include gradient-bg(linear, 90deg, rgba($primary_400, 90%) 9%, rgba($primary_700, 0%) 100%);
        position: absolute;
        inset: 0;
        border-radius: inherit;
        z-index: 1;
    }
}

.promo_text_cnt {
    position: absolute;
    @include middle_align();
    z-index: 1;
    margin-left: 13px;
}

.promo_text {
    display: table-caption;
    position: relative;
    @include title($white_200, $secondary_font, 25px, bold, uppercase, unset);
    line-height: 25px;

}

.media_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include center_middle_align();
    position: absolute;
    z-index: 0;
    border-radius: 12px;
}

.promo_btn {
    @include button(auto,
        auto,
        uppercase,
        $white,
        $linear-light_green-gradient-rightAngle,
        1rem,
        bold,
        $main_font,
        100px,
        linear-gradient(45deg, rgba(140, 66, 29, 1) 0%, rgba(252, 251, 231, 1) 19%, rgba(251, 230, 123, 1) 55%, rgba(247, 209, 78, 1) 77%, rgba(212, 160, 65, 1) 100%),
        none, 1px);
    margin: auto;
    aspect-ratio: 153 / 35;
    min-width: 153px;
    min-height: 35px;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$mobile} {
        width: 157px;
        height: 35px;
        font-size: 16px;
        padding: 0;
        margin: 8px auto;
    }
}

.scrollContainer {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    grid-auto-columns: calc(25% - 11px);
    padding: 1px 28px 2rem 1rem;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    @include center_middle_align();
    transform: translate(-50%, -35%);
    position: relative;

    @media #{$tablet} {
        grid-auto-columns: calc(34% - 10px);
    }

    @media #{$mobile} {
        transform: none;
        top: auto;
        left: auto;
        grid-auto-columns: 100%;
        padding: 20px 1px;
        margin: 20px 0 24px;

    }

    &::-webkit-scrollbar {
        width: 10px;
        background: transparent;
        height: 5px;
        margin-top: 10px;
    }

    &::-webkit-scrollbar-track {
        margin: 0 350px;
        background: #D7DED7;
        border-radius: 100px;
        cursor: pointer;

        @media #{$tabDesk} {
            margin: 0 200px;
        }

        @media #{$mobile} {
            margin: 0;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: $primary_400;
        height: 10px;
        width: 1px;
        border-radius: 100px;
        cursor: pointer;
    }
}

.articlePage {
    transform: none;
    inset: unset;
}

.snaps_inline {
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: var(--_spacer, 1rem);
}

.snaps_inline>* {
    scroll-snap-align: start;
}