@import '../../sass/styles.scss';

.tooltipContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
}

.tooltipIcon {
    background-color: $primary_400;
    color: white;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}

.tooltipBox {
    position: absolute;
    left: 0;
    bottom: 110%;
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: wrap;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
}
