@import "../../sass/styles.scss";

.return_container {
    text-align: center;
    border-radius: 5px;
}

.icon {
    display: inline-block;
    height: 25px;
    vertical-align: middle;
}

.error {
    & .message {
        color: $error;
    }
}

.success {
    & .message {
        color: #155724;
    }
}

.message {
    @include title(inherit, $main_font, 16px, 500, capitalize, unset);
    display: inline-block;
    vertical-align: middle;
    margin-left: 7.5px;

    @media #{$mobile} {
        font-size: 12px;
    }
}